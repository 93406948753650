body.modal-open {
    height: 100vh;
    overflow-y: hidden;
}

@media only screen and (min-width: 800px) {
    div.c-modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(4px);
        z-index: 1071;
    }

    div.modal {
        top: calc(50% - 200px);
        z-index: 1072;
    }
    
        div.modal div.modal-body {
            padding: 0;
        }
    
        div.modal div.modal-content {
            height: 350px;
        }
    
        div.modal i.fa {
            font-size: 40px;
            color: #ffd200;
            width: 40px;
            height: 40px;
            text-align: center;
            cursor: pointer;
        }
    
        div.modal i.modal-prev {
            position: fixed;
            top: calc(50% - 20px);
            left: calc(50% - 450px);
        }
    
        div.modal i.modal-next {
            position: fixed;
            top: calc(50% - 20px);
            left: calc(50% + 410px);
        }
    
        div.modal i.modal-close {
            position: fixed;
            top: calc(50% - 215px);
            left: calc(50% + 400px);
            color: #fff;
        }
    
        div.modal div.caption {
            float: left;
            width: 425px;
            padding: 15px;
            box-sizing: border-box;
        }
    
            div.modal div.caption h3 {
                margin: 0 0 15px 0;
                font-family: 'Roboto', Arial, sans-serif;
                font-size: 30px;
                font-weight: bold;
                color: #333;
            }
    
            div.modal div.caption p {
                font-size: 18px;
                text-align: left;
            }
    
        div.modal img {
            float: right;
            margin-top: 25px;
        }
}

@media only screen and (max-width: 799px) {
    div.modal {
        top: calc(50% - 200px);
    }
    
        div.modal div.modal-body {
            padding: 0;
        }
    
        div.modal div.modal-content {
            
        }
    
        div.modal i.fa {
            font-size: 40px;
            color: #ffd200;
            width: 40px;
            height: 40px;
            text-align: center;
            cursor: pointer;
        }
    
        div.modal i.modal-prev {
            position: fixed;
            top: calc(50% - 20px);
            left: 20px;
        }
    
        div.modal i.modal-next {
            position: fixed;
            top: calc(50% - 20px);
            right: 20px;
        }
    
        div.modal i.modal-close {
            position: fixed;
            top: calc(50% - 215px);
            right: 60px;
            color: #fff;
        }
    
        div.modal div.caption {
            float: left;
            padding: 15px;
            box-sizing: border-box;
        }
    
            div.modal div.caption h3 {
                margin: 0 0 15px 0;
                font-family: 'Roboto', Arial, sans-serif;
                font-size: 30px;
                font-weight: bold;
                color: #333;
                text-align: center;
            }
    
            div.modal div.caption p {
                font-size: 18px;
                text-align: center;
            }
    
        div.modal img {
            display: none;
        }
}