@media only screen and (min-width: 800px) {
    div#howwedoit {
        background-color: #f2f2f2;
        padding: 50px 0;
        box-sizing: border-box;
    }

        div#howwedoit p {
            text-align: center;
            font-size: 20px;
        }

        div#howwedoit div.tool-images {
            text-align: center;
            height: 150px;
            padding: 50px 0;
            box-sizing: content-box;
        }

            div#howwedoit div.tool-images img {
                cursor: pointer;
            }

            div#howwedoit div.tool-images img.preload {
                display: none;
            }

        div#howwedoit div.tool-info {
        }

            div#howwedoit div.tool-info div.tool-txt {
                display: none;
                float: left;
                width: 900px;
                height: 120px;
            }

                div#howwedoit div.tool-info div.tool-txt h3 {
                    text-align: center;
                }

            div#howwedoit div.tool-info i.fa-angle-left {
                float: left;
                color: #f7cc52;
                font-size: 60px;
                cursor: pointer;
                width: 50px;
                line-height: 240px;
            }

            div#howwedoit div.tool-info i.fa-angle-right {
                float: right;
                color: #f7cc52;
                font-size: 60px;
                cursor: pointer;
                width: 50px;
                text-align: right;
                line-height: 240px;
            }

        div#howwedoit div.tool-mobile {
            display: none;
        }
}

@media only screen and (max-width: 799px) {
    div#howwedoit {
        background-color: #f2f2f2;
        padding: 30px 0;
        box-sizing: border-box;
    }

        div#howwedoit p {
            text-align: center;
            font-size: 20px;
            padding: 0 25px;
        }A
        div#howwedoit div.tool-info {
            display: none;
        }

        div#howwedoit div.tool-mobile {
        }

            div#howwedoit div.tool-mobile div.tool-txt {
                padding: 0 25px;
            }

                div#howwedoit div.tool-mobile div.tool-txt img {
                    float: left;
                }

                div#howwedoit div.tool-mobile div.tool-txt p {
                    float: right;
                    text-align: left;
                    width: calc(100% - 150px);
                }
}