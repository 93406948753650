@media only screen and (min-width: 800px) {
    body {
        font-family: 'Montserrat', 'Arial', sans-serif;
        font-size: 18px;
        padding: 0;
        margin: 0;
    }

    div.wrap {
        width: 1000px;
        margin: 0 auto;
    }

    br.clearfix {
        clear: both;
    }

    div#nav {
        box-sizing: border-box;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 75px;
        padding: 25px 40px;
        z-index: 10;
    }

        div#nav img {
            float: left;
        }

        div#nav ul {
            float: right;
            list-style: none;
            margin: 0;
            padding: 12px 0 0 0;
            width: calc(100% - 162px);
            height: 75px;
        }

            div#nav ul li {
                margin: 0;
                padding: 0;
            }

                div#nav ul li a {
                    display: block;
                    float: right;
                    line-height: 50px;
                    padding: 0 20px;
                    color: #fff;
                    text-decoration: none;
                    font-weight: bold;
                    font-family: 'Roboto', 'Arial', sans-serif;
                }

                    div#nav ul li a.active {
                        color: #ffd200;
                    }

                    div#nav ul li a.yellow-box {
                        background-color: #ffd200;
                        color: #000;
                    }

    div#nav-floating {
        box-sizing: border-box;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 105px;
        padding: 15px 20px;
        z-index: 15;
        background-color: #fff;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    }

        div#nav-floating img {
            float: left;
        }

        div#nav-floating ul {
            float: right;
            list-style: none;
            margin: 0;
            padding: 12px 0 0 0;
            width: calc(100% - 162px);
            height: 75px;
        }

            div#nav-floating ul li {
                margin: 0;
                padding: 0;
            }

                div#nav-floating ul li a {
                    display: block;
                    float: right;
                    line-height: 50px;
                    padding: 0 20px;
                    color: #333;
                    text-decoration: none;
                    font-weight: bold;
                    font-family: 'Roboto', 'Arial', sans-serif;
                }

                    div#nav-floating ul li a.active {
                        color: #ffd200;
                    }

                    div#nav-floating ul li a.yellow-box {
                        background-color: #ffd200;
                        color: #000;
                    }

    div#nav-mobile {
        display: none;
    }

    div#nav-mobile-menu {
        display: none;
    }

    h1.center-title {
        color: #333;
        text-align: center;
        font-weight: bold;
        text-transform: uppercase;
        margin: 0;
        padding: 15px 0;
    }

    div#footer {
        padding: 20px 0 10px 0;
        background-color: #333;
    }

        div#footer p.left-foot {
            float: left;
            color: #aaa;
            padding-left: 20px;
            line-height: 25px;
        }

        div#footer p.right-foot {
            float: right;
            color: #aaa;
            padding-right: 20px;
            line-height: 25px;
        }

            div#footer p.right-foot a {
                color: #aaa;
            }

                div#footer p.right-foot a.fa-angle-up {
                    color: #ffd200;
                    font-size: 60px;
                    text-decoration: none;
                    float: right;
                }
}

@media only screen and (max-width: 799px) {
    body {
        font-family: 'Montserrat', 'Arial', sans-serif;
        font-size: 18px;
        padding: 0;
        margin: 0;
    }

    div.wrap {
        width: 100%;
    }

    br.clearfix {
        clear: both;
    }

    div#nav {
        display: none;
    }

    div#nav-floating {
        display: none;
    }

    div#nav-mobile {
        box-sizing: border-box;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 75px;
        padding: 15px 30px;
        z-index: 10;
    }

        div#nav-mobile img {
            float: left;
        }

        div#nav-mobile span.fa-bars {
            display: block;
            float: right;
            width: 75px;
            line-height: 75px;
            cursor: pointer;
            color: #fff;
            font-size: 30px;
            text-align: center;
        }

    div#nav-mobile-menu {
        width: 50%;
        position: absolute;
        right: 35px;
        top: 75px;
        background-color: #fff;
        border-radius: 10px;
        padding: 10px 0;
    }

        div#nav-mobile-menu ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }

            div#nav-mobile-menu ul li a {
                display: block;
                text-align: right;
                line-height: 40px;
                font-size: 22px;
                padding-right: 15px;
                color: #555;
                text-decoration: none;
            }

    h1.center-title {
        color: #333;
        text-align: center;
        font-weight: bold;
        text-transform: uppercase;
        margin: 0;
        padding: 15px 0;
    }

    div#footer {
        padding: 20px 0 10px 0;
        background-color: #333;
    }

        div#footer p.left-foot {
            float: left;
            color: #aaa;
            padding-left: 20px;
            line-height: 25px;
        }

        div#footer p.right-foot {
            float: right;
            color: #aaa;
            padding-right: 20px;
            line-height: 25px;
        }

            div#footer p.right-foot a {
                color: #aaa;
            }

                div#footer p.right-foot a.fa-angle-up {
                    color: #ffd200;
                    font-size: 60px;
                    text-decoration: none;
                    float: right;
                }
}
