@media only screen and (min-width: 800px) {
    div#whoweare {
        background-color: #fff;
        padding: 0 0 30px 0;
        box-sizing: border-box;
    }

        div#whoweare div#clients {
            text-align: center;
            opacity: 0.5;
            height: 80px;
            padding: 50px 0;
            box-sizing: content-box;
        }

            div#whoweare div#clients img {
                margin: 0 20px;
            }

        div#whoweare div#clients-caption {
            opacity: 0;
            width: 100%;
            background-color: rgba(255, 255, 255, 0.75);
            text-align: center;
            font-weight: bold;
            line-height: 180px;
            margin-top: -180px;
            transition: opacity 0.25s;
        }

        div#whoweare div#clients-caption:hover {
            opacity: 1;
            position: absolute;
            width: 100%;
            background-color: rgba(255, 255, 255, 0.75);
            text-align: center;
            font-weight: bold;
            line-height: 180px;
        }

        div#whoweare hr {
            border: 1px solid #eee;
            width: 50%;
            margin: 0 auto 20px auto;
        }

        div#whoweare p#para1 {
            text-align: center;
            width: 1000px;
            margin: 40px auto 20px auto;
            font-size: 20px;
        }

        div#whoweare div#para2 {
            width: 1000px;
            margin: 40px auto 20px auto;
        }

            div#whoweare div#para2 p {
                text-align: center;
                width: 600px;
                float: left;
                font-size: 20px;
            }

            div#whoweare div#para2 img {
                float: right;
                margin: 0 75px;
            }
}

@media only screen and (max-width: 799px) {
    div#whoweare {
        background-color: #fff;
    }

        div#whoweare div#clients {
            display: none;
        }

            div#whoweare div#clients img {
                margin: 0 20px;
            }

        div#whoweare div#clients-caption {
            display: none;
        }

        div#whoweare div#clients-mobile {
            padding: 25px 0;
            text-align: center;
            opacity: 0.5;
        }

        div#whoweare hr {
            border: 1px solid #eee;
            width: 50%;
            margin: 0 auto 20px auto;
        }

        div#whoweare p#para1 {
            text-align: center;
            font-size: 20px;
            padding: 0 25px;
        }

        div#whoweare div#para2 {
            text-align: center;
        }

            div#whoweare div#para2 p {
                text-align: center;
                font-size: 20px;
                padding: 0 25px;
            }

            div#whoweare div#para2 img {
                margin: 0 auto;
            }
}