@media only screen and (min-width: 800px) {
    div#webapps {
        background-color: #f2f2f2;
        padding: 50px 0;
        box-sizing: border-box;
    }

        div#webapps img.webapps-img {
            float: left;
        }

        div#webapps div.info {
            float: right;
            width: 650px;
        }

            div#webapps div.info p {
                font-size: 20px;
            }

            div#webapps div.info div.technologies {
                height: 125px;
            }

                div#webapps div.info div.technologies img {
                    margin-right: 5px;
                    float: left;
                }


    span.view-our-work {
        display: block;
        float: right;
        height: 40px;
        cursor: pointer;
    }

        span.view-our-work span.linktext {
            line-height: 40px;
            float: left;
            text-decoration: none;
            color: #777;
            font-weight: bold;
        }

        span.view-our-work span.arrow {
            color: #f7cc52;
            font-size: 40px;
            line-height: 40px;
            float: right;
            margin-left: 10px;
        }
}

@media only screen and (max-width: 799px) {
    div#webapps {
        background-color: #f2f2f2;
        padding: 30px 0;
        box-sizing: border-box;
        text-align: center;
    }

        div#webapps img.webapps-img {
        }

        div#webapps div.info {
        }

            div#webapps div.info p {
                font-size: 20px;
                padding: 0 25px;
            }

            div#webapps div.info div.technologies {
                height: 125px;
                text-align: center;
                margin-bottom: 25px;
            }

                div#webapps div.info div.technologies img {
                }

    span.view-our-work {
        display: block;
        height: 40px;
        cursor: pointer;
        width: 175px;
        margin: 0 auto;
    }

        span.view-our-work span.linktext {
            line-height: 40px;
            float: left;
            text-decoration: none;
            color: #777;
            font-weight: bold;
        }

        span.view-our-work span.arrow {
            color: #f7cc52;
            font-size: 40px;
            line-height: 40px;
            float: right;
            margin-left: 10px;
        }
}