@media only screen and (min-width: 800px) {
    div#main {
        background-color: #fff;
        padding: 50px 0;
        box-sizing: border-box;
    }
    
        div#main div#clients {
            text-align: center;
            opacity: 0.5;
            height: 160px;
            padding: 30px 0 70px 0;
            width: 1000px;
            margin: 0 auto;
            box-sizing: content-box;
        }
    
            div#main div#clients img {
                margin: 0 20px 10px 20px;
            }
    
        div#main hr {
            border: 1px solid #eee;
            width: 50%;
            margin: 0 auto 20px auto;
        }
    
        div#main div.feature-tile {
            box-sizing: border-box;
            margin: 5px 5px 10px 5px;
            height: 150px;
        }
    
            div#main div.feature-tile span.fa {
                display: block;
                color: #53b5d6;
                float: left;
                font-size: 45px;
                width: 55px;
                text-align: center;
                margin-right: 15px;
            }
    
            div#main div.feature-tile svg {
                float: left;
                margin-right: 15px;
                width: 55px;
            }
    
                div#main div.feature-tile svg path {
                    fill: #53B5D6;
                }
    
            div#main div.feature-tile p {
                float: left;
                margin: 0;
                font-size: 16px;
                width: 395px;
            }
    
    div.left-col {
        float: left;
        width: 500px;
    }
    
    div.right-col {
        float: right;
        width: 500px;
    }
}

@media only screen and (max-width: 799px) {
    div#main {
        background-color: #fff;
        padding: 50px 0;
        box-sizing: border-box;
    }
    
        div#main div#clients {
            text-align: center;
            opacity: 0.5;
            padding: 20px 0 40px 0;
            box-sizing: content-box;
        }
    
            div#main div#clients img {
                margin: 0 20px 10px 20px;
            }
    
        div#main hr {
            border: 1px solid #eee;
            width: 50%;
            margin: 0 auto 20px auto;
        }
    
        div#main div.feature-tile {
            box-sizing: border-box;
            padding: 0 0 25px 0;
            margin: 0 auto;
            width: 80%;
            text-align: justify;
        }
    
            div#main div.feature-tile span.fa {
                display: block;
                color: #53b5d6;
                float: left;
                font-size: 45px;
                width: 55px;
                text-align: center;
                margin-right: 15px;
            }
    
            div#main div.feature-tile svg {
                float: left;
                margin-right: 15px;
                width: 55px;
            }
    
                div#main div.feature-tile svg path {
                    fill: #53B5D6;
                }
    
            div#main div.feature-tile p {
                float: left;
                margin: 0;
                font-size: 16px;
                width: calc(100% - 75px);
            }
    
    div.left-col {

    }
    
    div.right-col {

    }
}