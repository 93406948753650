@media only screen and (min-width: 800px) {
    div#hero-antidoping {
        height: 100vh;
        background-image: url('../images/hero-antidoping.jpg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        z-index: 0;
    }

        div#hero-antidoping h1.hero-title {
            font-size: 60px;
            color: #fff;
            font-weight: bold;
            font-family: 'Roboto', 'Arial', sans-serif;
            margin: 0;
            text-transform: uppercase;
            position: relative;
            top: 40%;
            left: 40px;
            width: calc(100% - 40px);
        }

        div#hero-antidoping p {
            font-size: 18px;
            color: #fff;
            line-height: 25px;
            position: relative;
            top: 40%;
            left: 40px;
            width: calc(100% - 40px);
        }
}

@media only screen and (max-width: 799px) {
    div#hero-antidoping {
        height: 100vh;
        background-image: url('../images/hero-antidoping.jpg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        z-index: 0;
    }

        div#hero-antidoping h1.hero-title {
            font-size: 50px;
            color: #fff;
            font-weight: bold;
            font-family: 'Roboto', 'Arial', sans-serif;
            margin: 0;
            text-transform: uppercase;
            position: relative;
            top: 40%;
            left: 40px;
            width: calc(100% - 40px);
        }

        div#hero-antidoping p {
            display: none;
        }
}