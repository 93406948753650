@media only screen and (min-width: 800px) {
    div#contactus {
        background-color: #ffd200;
        padding: 50px 0 60px 0;
        box-sizing: border-box;
    }

        div#contactus input {
            border: 1px solid #aaa;
            background-color: #fff;
            height: 35px;
            line-height: 35px;
            padding-left: 10px;
            width: 100%;
            margin-bottom: 15px;
            font-family: 'Montserrat', 'Arial', sans-serif;
            font-size: 16px;
        }

        div#contactus textarea {
            border: 1px solid #aaa;
            background-color: #fff;
            height: 185px;
            padding: 10px;
            width: 100%;
            margin-bottom: 15px;
            font-family: 'Montserrat', 'Arial', sans-serif;
            font-size: 16px;
        }

        div#contactus button {
            margin: 30px 0 10px 300px;
            width: 400px;
            height: 45px;
            border: 2px solid #000;
            background-color: transparent;
            color: #000;
            font-weight: bold;
            font-size: 20px;
            text-align: center;
            cursor: pointer;
        }

        div#contactus div.left-form {
            float: left;
            width: 490px;
        }

        div#contactus div.right-form {
            float: right;
            width: 490px;
        }

        div#contactus div.success-msg {
            padding: 10px;
            text-align: center;
            font-weight: bold;
            color: #555;
            border: 1px solid #fff;
            margin-bottom: 15px;
        }

    #g-recaptcha {
        clear: both;
    }

        #g-recaptcha div:first-child {
            margin: 0 auto;
        }
}

@media only screen and (max-width: 799px) {
    div#contactus {
        background-color: #ffd200;
        padding: 30px 0 40px 0;
        box-sizing: border-box;
    }

        div#contactus input {
            border: 1px solid #aaa;
            background-color: #fff;
            height: 35px;
            line-height: 35px;
            padding-left: 10px;
            width: calc(100% - 30px);
            margin: 0 0 15px 15px;
            font-family: 'Montserrat', 'Arial', sans-serif;
            font-size: 16px;
            box-sizing: border-box;
        }

        div#contactus textarea {
            border: 1px solid #aaa;
            background-color: #fff;
            height: 180px;
            padding: 10px;
            font-family: 'Montserrat', 'Arial', sans-serif;
            font-size: 16px;
            width: calc(100% - 30px);
            margin: 0 0 15px 15px;
            box-sizing: border-box;
        }

        div#contactus button {
            width: calc(100% - 50px);
            height: 45px;
            border: 2px solid #000;
            background-color: transparent;
            color: #000;
            font-weight: bold;
            font-size: 20px;
            text-align: center;
            cursor: pointer;
            margin: 30px 0 0 25px;
        }

        div#contactus div.left-form {
            box-sizing: border-box;
        }

        div#contactus div.right-form {
            box-sizing: border-box;
        }

        div#contactus div.success-msg {
            padding: 10px;
            text-align: center;
            font-weight: bold;
            color: #555;
            border: 1px solid #fff;
            width: 80%;
            margin: 0 auto 15px auto;
        }

    #g-recaptcha {
        clear: both;
    }

        #g-recaptcha div:first-child {
            margin: 0 auto;
        }
}