@media only screen and (min-width: 800px) {
    div#mobileapps {
        background-color: #fff;
        padding: 50px 0;
        box-sizing: border-box;
    }

        div#mobileapps img.platforms {
            float: left;
        }

        div#mobileapps p {
            float: left;
            text-align: center;
            width: 600px;
            padding: 0 25px;
            box-sizing: border-box;
            font-size: 20px;
        }

        div#mobileapps img.mobileapps-img {
            float: left;
        }
}

@media only screen and (max-width: 799px) {
    div#mobileapps {
        background-color: #fff;
        padding: 30px 0;
        box-sizing: border-box;
        text-align: center;
    }

        div#mobileapps img.platforms {
        }

        div#mobileapps p {
            text-align: center;
            padding: 0 25px;
            box-sizing: border-box;
            font-size: 20px;
        }

        div#mobileapps img.mobileapps-img {
            margin-bottom: 25px;
        }
}